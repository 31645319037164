/* eslint-disable camelcase */
// noinspection JSXUnresolvedComponent

import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import bgEaPlay from './background/bg-ea-play.jpg';
import bgUbiPlus from './background/bg-ubisoft-plus.jpg';
import bgDefault from './background/bg-image.jpg';
import { useLocalStorage } from './hooks/useLocalStorage';

const DynamicPackageContext = createContext(null);

const DynamicPackageProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [tagline, setTagline] = useState(null);
  const [background, setBackground] = useState(bgDefault);

  const [canSwitchPlan, setCanSwitchPlan] = useState(false);
  const [billingPeriod, setBillingPeriod] = useLocalStorage('dynamicPackage.pref.billingPeriod', 'Monthly');

  const runDynamicPackageDecisions = useCallback(async ({ viewFrom, useMock }) => {
    if (useMock) {
      setLoading(false);
      setProducts([1, 2, 3, 4, 5, 6]
        .map((i) => ({
          id: `dynamic--${i}`,
          label: `Game Pass ${i}`,
          description: '',
          features: [
            {
              id: 'ea-play',
              label: 'EA Play',
              description: 'A library of top Electronic Arts titles, exclusive rewards, and member-only content',
            },
            {
              id: 'riot-games',
              label: 'Riot Games',
              description: 'Play casually with our range of fun mobile games',
            },
            {
              id: 'ubisoft-plus',
              label: 'Ubisoft+',
              description: 'A library of top Ubisoft titles, exclusive rewards, and member-only content',
            },
          ],
          prices: [
            {
              ratePlanId: '8ad0938487b72f350187b913e4c76748',
              charges: [
                {
                  productChargeId: '8ad0938487b72f350187b914e440732b',
                  priceBookItems: [
                    {
                      id: '8ad0938487b72f350187b914e48d7351',
                      number: 'PBI-00001071',
                      productChargeId: '8ad0938487b72f350187b914e440732b',
                      offerId: null,
                      offerNumber: null,
                      effectiveStartDate: '2023-04-25',
                      effectiveEndDate: '2026-04-25',
                      currency: 'GBP',
                      defaultQuantity: 1.0,
                      listPriceBase: 'Per_Billing_Period',
                      includedUnits: null,
                      price: 5.0,
                      overagePrice: null,
                      tiers: null,
                      intervalPrices: null,
                      BillingPeriod__c: 'Monthly',
                      Domain__c: null,
                      SalesOrg__c: null,
                      Vendor__c: null,
                      CustomerSegment__c: null,
                      SubscriptionTerm__c: null,
                      IsReseller__c: null,
                      Region__c: null,
                    },
                  ],
                },
              ],
            },
            {
              ratePlanId: '8ad096ca87b72f360187b91544bd4fca',
              charges: [
                {
                  productChargeId: '8ad096ca87b72f360187b915cc835824',
                  priceBookItems: [
                    {
                      id: '8ad096ca87b72f360187b915ccca5832',
                      number: 'PBI-00001076',
                      productChargeId: '8ad096ca87b72f360187b915cc835824',
                      offerId: null,
                      offerNumber: null,
                      effectiveStartDate: '2023-04-25',
                      effectiveEndDate: '2026-04-25',
                      currency: 'GBP',
                      defaultQuantity: 1.0,
                      listPriceBase: 'Per_Billing_Period',
                      includedUnits: null,
                      price: 50.0,
                      overagePrice: null,
                      tiers: null,
                      intervalPrices: null,
                      BillingPeriod__c: 'Annual',
                      Domain__c: null,
                      SalesOrg__c: null,
                      Vendor__c: null,
                      CustomerSegment__c: null,
                      SubscriptionTerm__c: null,
                      IsReseller__c: null,
                      Region__c: null,
                    },
                  ],
                },
              ],
            },
          ],
        })));
    } else {
      setLoading(false);
      try {
        const blaizeSession = Cookies.get('blaize_session');
        if (!blaizeSession) {
          const newBlaizeSession = uuid();
          Cookies.set('blaize_session', newBlaizeSession);
        }

        const dynamicPackage = localStorage.getItem('dynamicPackage.slug');
        if (!dynamicPackage) {
          const defaultDynamicPackage = 'xgp-dynamic';
          localStorage.setItem('dynamicPackage.slug', defaultDynamicPackage);
        }

        const data = await fetch(
          '/zephr/public/decisions/v1/dynamic-packages', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              dynamic_package: {
                slug: dynamicPackage ?? localStorage.getItem('dynamicPackage.slug'),
                inputs: {
                  view_from: viewFrom,
                },
              },
              session: blaizeSession ?? Cookies.get('blaize_session'),
              user_agent: window.navigator.userAgent,
            }),
          })
          .then((res) => res.json())
          .catch((e) => {
            console.log(e);
            return null;
          });

        if (data) {
          if (data.products && data.products.length > 0) {
            setProducts(data.products);
          }
          if (data.tagline) {
            setTagline(data.tagline);
          }
          if (data.custom) {
            const bgValue = data.custom['background-image'];
            switch (bgValue) {
              case 'bg-ea-play':
                setBackground(bgEaPlay);
                break;
              case 'bg-ubisoft-plus':
                setBackground(bgUbiPlus);
                break;
              default:
                setBackground(bgDefault);
                break;
            }
          }
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const decisions = useMemo(() => ({
    loading,
    products,
    tagline,
    background,
    runDynamicPackageDecisions,
    billingPeriod,
    setBillingPeriod,
    canSwitchPlan,
  }), [
    loading,
    products,
    tagline,
    background,
    runDynamicPackageDecisions,
    billingPeriod,
    setBillingPeriod,
    canSwitchPlan,
  ]);

  useEffect(() => {
    const pbis = products
      .flatMap(({ prices }) => prices ?? [])
      .flatMap(({ charges }) => charges ?? [])
      .flatMap(({ priceBookItems }) => priceBookItems ?? []);

    if (pbis.length > 0) {
      const billingPeriods = [...new Set(pbis.map(({ BillingPeriod__c }) => BillingPeriod__c).filter((bp) => bp != null))];
      if (billingPeriods.length > 1) {
        setCanSwitchPlan(true);
      } else if (billingPeriods.length > 0) {
        const [bp] = billingPeriods;
        setBillingPeriod(bp === 'Monthly' || bp === 'Annual' ? bp : 'Monthly');
        setCanSwitchPlan(false);
      } else {
        setBillingPeriod('Monthly');
        setCanSwitchPlan(false);
      }
    }
  }, [products, setBillingPeriod]);

  return (
    <DynamicPackageContext.Provider
      value={
        decisions
      }
    >
      {children}
    </DynamicPackageContext.Provider>
  );
};

const useDynamicPackage = () => useContext(DynamicPackageContext);

export {
  DynamicPackageContext,
  DynamicPackageProvider,
  useDynamicPackage,
};
