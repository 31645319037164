/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { formatPrice, PriceDisplay } from './PriceDisplay';
import { Feature } from './Feature';
import { JoinButton } from './JoinButton';

export const Product = ({
  product,
  billingPeriod,
  canSwitchPlan,
}) => {
  const [currency, setCurrency] = useState(null);
  const [price, setPrice] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [chosenCharge, setChosenCharge] = useState(null);
  const [applicableDiscounts, setApplicableDiscounts] = useState([]);

  const getOffsetString = useCallback((policy) => {
    let billingPeriodString = '';
    switch (policy.offset.unit) {
      case 'CHARGE_BILLING_PERIOD':
        billingPeriodString = billingPeriod === 'Annual' ? 'year' : 'month';
        break;
      default:
        billingPeriodString = policy.offset.unit.toLowerCase();
    }
    return `${policy.offset.value} ${billingPeriodString}(s)`;
  }, [billingPeriod]);

  const getCustomPolicyString = useCallback((policy) => {
    switch (policy.type) {
      case 'ON_CHARGE_START':
        return 'subscription start';
      case 'ON_CHARGE_END':
        return 'subscription end';
      case 'AFTER_CHARGE_START':
        return `${getOffsetString(policy)} after subscription start`;
      case 'BEFORE_CHARGE_END':
        return `${getOffsetString(policy)} before subscription end`;
      default:
        return '';
    }
  }, [getOffsetString]);

  const getPolicyString = useCallback(({ startPolicy, endPolicy }) => {
    if (startPolicy.type === 'ON_CHARGE_START') {
      switch (endPolicy?.type) {
        case 'ON_CHARGE_START':
          return 'when you subscribe';
        case 'ON_CHARGE_END':
          return `every ${billingPeriod === 'Annual' ? 'year' : 'month'}`;
        case 'AFTER_CHARGE_START':
          return `for the first ${getOffsetString(endPolicy)}`;
        case 'BEFORE_CHARGE_END':
          return `until ${getOffsetString(endPolicy)} before subscription ends`;
        default:
          break;
      }
    }

    if (startPolicy.type === 'ON_CHARGE_END') {
      switch (endPolicy?.type) {
        case 'ON_CHARGE_END':
          return 'at the end of your subscription';
        default:
          break;
      }
    }

    return `between ${getCustomPolicyString(startPolicy)} and ${getCustomPolicyString(endPolicy)}`;
  }, [billingPeriod, getOffsetString, getCustomPolicyString]);

  const applyRecurringDiscount = useCallback((startPrice, endPolicy, value, type) => {
    // For now assume it starts on charge start date
    let multiplier = 0;
    let amount;

    switch (type) {
      case 'PERCENTAGE':
        amount = startPrice * (value / 100);
        break;
      case 'OVERRIDE':
        amount = startPrice - value;
        break;
      default:
        amount = value;
        break;
    }

    switch (endPolicy.type) {
      case 'AFTER_CHARGE_START':
        switch (endPolicy.offset.unit) {
          case 'CHARGE_BILLING_PERIOD':
            multiplier = 1;
            break;
          case 'YEAR':
            multiplier = 1;
            break;
          case 'MONTH':
            multiplier = billingPeriod === 'Monthly' ? 1 : endPolicy.offset.value;
            switch (type) {
              case 'PERCENTAGE':
                amount = billingPeriod === 'Monthly' ? startPrice * (value / 100) : (startPrice / 12) * (value / 100);
                break;
              case 'OVERRIDE':
                amount = billingPeriod === 'Monthly' ? startPrice - value : (startPrice / 12) - value;
                break;
              default:
                amount = value;
                break;
            }
            break;
            // Assumed 30 day months
          case 'DAY':
            multiplier = endPolicy.offset.value;
            switch (type) {
              case 'PERCENTAGE':
                amount = billingPeriod === 'Monthly' ? (startPrice / 30) * (value / 100) : (startPrice / 365) * (value / 100);
                break;
              case 'OVERRIDE':
                amount = billingPeriod === 'Monthly' ? (startPrice / 30) - value : (startPrice / 365) - value;
                break;
              default:
                amount = value;
                break;
            }
            break;
          default:
            multiplier = endPolicy.offset.value;
            break;
        }
        break;
      case 'ON_CHARGE_START':
        multiplier = 1;
        break;
      case 'ON_CHARGE_END':
        multiplier = 1;
        break;
        // Can't handle this case without knowing how long it's going to be
      case 'BEFORE_CHARGE_END':
        break;
      default:
        break;
    }

    return startPrice - (multiplier * amount);
  }, [billingPeriod]);

  const calculateDiscount = useCallback((discount, currentPrice) => {
    let newPrice = currentPrice;
    discount.items.forEach((d) => {
      const discountText = `
          ${(d.type === 'PERCENTAGE') ? `${d.value}%` : formatPrice({
    currency,
    price: d.value,
    hideZeroFraction: true,
  })} 
          ${d.type !== 'OVERRIDE' ? 'off' : ''} ${getPolicyString(d)}`;
      setApplicableDiscounts((currentlyAppliedDiscounts) => (!currentlyAppliedDiscounts.includes(discountText) ? [...currentlyAppliedDiscounts, discountText] : currentlyAppliedDiscounts));
      newPrice = applyRecurringDiscount(newPrice, d.endPolicy, d.value, d.type);
    });

    return newPrice;
  }, [setApplicableDiscounts, getPolicyString, currency, applyRecurringDiscount]);

  useEffect(() => {
    if (billingPeriod && (product.prices ?? []).length > 0) {
      const availableCharges = product.prices.flatMap(({ charges }) => charges);
      const pbis = availableCharges
        .flatMap(({ priceBookItems }) => priceBookItems);

      let chosenPrice;
      if (canSwitchPlan) {
        [chosenPrice] = pbis.filter(({ BillingPeriod__c }) => BillingPeriod__c === billingPeriod);
      } else {
        const pricesWithBillingPeriod = pbis.filter(({ BillingPeriod__c }) => !!BillingPeriod__c);
        [chosenPrice] = pricesWithBillingPeriod.length > 0 ? pricesWithBillingPeriod : pbis;
      }

      if (chosenPrice) {
        setCurrency(chosenPrice.currency);
        setPrice(chosenPrice.price);
        setChosenCharge(availableCharges.find((c) => c.productChargeId === chosenPrice.productChargeId));
      }
    }
  }, [product, currency, billingPeriod, canSwitchPlan, calculateDiscount]);

  useEffect(() => {
    if (chosenCharge && currency && price) {
      if (chosenCharge?.discounts && chosenCharge.discounts.length > 0) {
        // Considers stacked discount outputs
        setApplicableDiscounts([]);
        let discountAmount = 0;
        chosenCharge.discounts.forEach((discount) => {
          discountAmount += price - calculateDiscount(discount, price);
        });
        setDiscountedPrice(price - discountAmount);
      } else {
        setDiscountedPrice(null);
        setApplicableDiscounts([]);
      }
    }
  }, [price, currency, chosenCharge, calculateDiscount]);

  if (!currency || !price) {
    return null;
  }

  return (
    <div
      className="flex flex-col flex-initial w-96 mb-auto p-6 max-w-lg text-center bg-gray-800 bg-opacity-95 border border-gray-600 shadow xl:p-8 rounded-l max-lg:rounded-r lg:border-r-0"
    >
      <h3 className="mb-4 text-2xl font-semibold">{product.label}</h3>
      {applicableDiscounts.length > 0 && applicableDiscounts.map((discount) => (
        <p
          key={discount}
          className="font-normal text-xbblue sm:text-lg pt-2"
        >
          {discount}
        </p>
      ))}
      <div className="flex justify-center items-baseline mt-6 mb-3">
        <PriceDisplay
          currency={currency}
          price={discountedPrice || price}
          hideZeroFraction
          superscriptFraction
        />
        <span className="text-gray-500 dark:text-gray-400">{billingPeriod === 'Monthly' ? '/month' : '/year'}</span>
      </div>
      {applicableDiscounts.length > 0 && (
      <div className="flex justify-center items-baseline gap-1">
        <span>Was: </span>
        <PriceDisplay currency={currency} price={price} superscriptFraction noStyle />
        <span className="text-gray-500 dark:text-gray-400">{billingPeriod === 'Monthly' ? '/month' : '/year'}</span>
      </div>
      )}
      <ul className="my-6 space-y-4 text-left xl:self-center">
        {product.features.map((f) => (
          <Feature key={f.id} text={f.description} />
        ))}
      </ul>
      <JoinButton
        className="flex self-center bg-transparent text-xbblue"
      >
        JOIN NOW FOR
        {' '}
        <PriceDisplay
          currency={currency}
          price={discountedPrice || price}
          hideZeroFraction
          noStyle
        />
      </JoinButton>
    </div>
  );
};
