// noinspection HtmlRequiredAltAttribute
import React from 'react';

// ea
import b2042 from './img/b2042.jpg';
import fifa from './img/fifa23.jpg';
import sims4 from './img/sims4.jpeg';

// ubi
import acv from './img/acv.jpeg';
import rss from './img/rss.png';
import fc6 from './img/fc6.jpeg';

// common
import vampireSurvivors from './img/vs.jpg';
import rtmi from './img/rtmi.jpg';
import fm2023 from './img/fm2023c.jpg';
import ghostSong from './img/gs.jpg';
import tianDing from './img/td.jpg';
import gr from './img/gr.jpg';

const SliderImage = ({ src, subtitle }) => (
  <div className="flex-none py-6 px-3 first:pl-6 last:pr-6">
    <div className="flex flex-col items-center justify-center gap-3">
      <img alt="" className="w-64 h-auto" src={src} />
      <strong className="text-slate-900 text-xs font-medium dark:text-slate-200">{subtitle}</strong>
    </div>
  </div>
);

const Slider = ({ viewFrom }) => (
  <div className="relative mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 -mt-16 lg:-mt-32">
    <div className="min-w-0">
      <h2 className="text-center text-3xl xl:text-4xl font-bold text-zinc-100">
        Discover your next favourite game
      </h2>
      <div className="overflow-x-auto flex">
        {(viewFrom || '').startsWith('ea') && (
          <SliderImage src={b2042} subtitle="Battlefield 2042" />
        )}
        {(viewFrom || '').startsWith('ea') && (
          <SliderImage src={fifa} subtitle="FIFA 2023" />
        )}
        {(viewFrom || '').startsWith('ea') && (
          <SliderImage src={sims4} subtitle="Sims4" />
        )}
        {(viewFrom || '').startsWith('ubi') && (
          <SliderImage src={acv} subtitle="Assassin's Creed Valhalla" />
        )}
        {(viewFrom || '').startsWith('ubi') && (
          <SliderImage src={rss} subtitle="Rainbow Six Siege" />
        )}
        {(viewFrom || '').startsWith('ubi') && (
          <SliderImage src={fc6} subtitle="Far Cry 6" />
        )}
        <SliderImage src={ghostSong} subtitle="Ghost Song" />
        <SliderImage src={gr} subtitle="Gunfire Reborn" />
        <SliderImage src={vampireSurvivors} subtitle="Vampire Survivors" />
        <SliderImage src={rtmi} subtitle="Return to Monkey Island" />
        <SliderImage src={fm2023} subtitle="Football Manager 2023" />
        <SliderImage src={tianDing} subtitle="The Legend of Tian Ding" />
      </div>
    </div>
  </div>
);

export default Slider;
