/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Slider from './Slider';
import { useDynamicPackage } from './DynamicPackageContext';
import Nav from './Nav';
import { JoinButton } from './JoinButton';

const Landing = () => {
  const {
    loading,
    tagline,
    background,
    runDynamicPackageDecisions,
  } = useDynamicPackage();

  const params = useParams();

  useEffect(() => {
    runDynamicPackageDecisions({
      viewFrom: params.viewFrom,
    });
  }, [runDynamicPackageDecisions, params.viewFrom]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Nav />
      <section
        className="relative bg-cover bg-center bg-no-repeat aspect-video -mt-28"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div
          className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-full lg:items-center lg:px-8 xl:px-2"
        >
          <div className="max-w-sm md:max-w-lg flex flex-col">
            <h1 className="text-5xl xl:text-6xl font-bold text-zinc-100">Game Pass</h1>
            <p className="mt-6 text-base text-zinc-100 font-bold">
              {tagline}
            </p>
            <JoinButton
              to={!params.viewFrom ? '/pricing' : `/${params.viewFrom}/pricing`}
              className="flex self-start mt-6 bg-transparent bg-xbblue text-stone-800"
            >
              Join now
              {' '}
            </JoinButton>
            <p className="mt-3 text-xs italic font-md">
              Subscription continues automatically at the monthly price unless cancelled through your Account.
              See
              {' '}
              <Link to="#" className="text-xbblue underline font-bold">terms.</Link>
            </p>
          </div>
        </div>
        <Slider viewFrom={params.viewFrom} />
      </section>
    </>
  );
};

export default Landing;
