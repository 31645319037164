/* eslint-disable no-nested-ternary */
import React from 'react';

export const formatPrice = ({ currency, price, hideZeroFraction }) => {
  const [whole, decimal] = new Intl.NumberFormat(
    currency === 'USD' ? 'en-US' : currency === 'GBP' ? 'en-GB' : undefined,
    {
      style: 'currency',
      currency,
    },
  )
    .format(price)
    .split('.');

  if (!hideZeroFraction || Number(decimal) > 0) {
    return [whole, decimal];
  }
  return [whole];
};

export const PriceDisplay = (
  {
    currency,
    price,
    hideZeroFraction,
    noStyle,
    superscriptFraction,
    subscriptFraction,
  },
) => {
  const [whole, fraction] = formatPrice({ currency, price, hideZeroFraction });
  const fractionValue = hideZeroFraction && !!fraction && Number(fraction) <= 0 ? '' : (fraction ? (`.${fraction}`) : '');

  if (noStyle) {
    return (
      <span>
        {whole}
        {fractionValue}
      </span>
    );
  }

  return (
    <span className="mr-2 text-5xl font-extrabold">
      {whole}
      {
        superscriptFraction
          ? (
            <sup
              className="text-3xl"
              style={{
                top: '-0.44em',
              }}
            >
              {fractionValue}
            </sup>
          )
          : subscriptFraction
            ? (
              <sub
                className="text-2xl"
                style={{
                  bottom: '0em',
                }}
              >
                {fractionValue}
              </sub>
            )
            : fractionValue
      }
    </span>
  );
};
