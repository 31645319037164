import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './Landing';
import Pricing from './Pricing';
import { DynamicPackageProvider } from './DynamicPackageContext';

const App = () => (
  <BrowserRouter>
    <DynamicPackageProvider>
      <div className="App flex h-full flex-col bg-xbdark text-zinc-100">
        <Routes>
          {['/', '/:viewFrom'].map((rp) => (
            <Route key={rp} path={rp}>
              <Route index element={<Landing />} />
              <Route path="pricing" element={<Pricing />} />
            </Route>
          ))}
          <Route path="*" element={<Landing />} />
        </Routes>
      </div>
    </DynamicPackageProvider>
  </BrowserRouter>
);

export default App;
