import React from 'react';

import { Link } from 'react-router-dom';

export const JoinButton = ({
  id,
  to,
  className,
  children,
}) => (
  <Link
    id={id}
    type="button"
    to={to}
    className={
      `${className} font-extrabold text-base border border-xbblue py-1 px-5 focus:outline-none focus:ring-2 focus:ring-green focus:ring-offset-2 focus:ring-offset-gray-800`
    }
  >
    <span className="hover:underline underline-offset-4 decoration-2">
      {children}
    </span>
    <span className="pl-1.5 text-2xl leading-5 no-underline inline-block">&rsaquo;</span>
  </Link>
);
