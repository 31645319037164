import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';

const Path = styled.div`
  position: absolute;
  background-color: #1f2937;
  width: 100%;
  height: 12px;
  top: 6px;
  border-radius: 7px;
`;

const Toggle = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #267de8;
  border-radius: 50%;
  background-color: #0b76f0;
  background-position: center center;
  background-size: cover;
  transition: 0.3s all;
  left: 0;
`;

const StyledSwitchEl = styled.div`
  position: relative;
  width: 48px;
  height: 26px;
  cursor: pointer;
  margin-top: 3px;
  ${({ isOn }) => isOn && `
    ${Toggle} {
      left: 23px;
    }
  `}
  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
`;

export const Switch = ({
  triggerAction,
  isOn,
  disabled,
  className,
}) => {
  const toggle = () => {
    if (disabled) return;
    triggerAction(!isOn);
  };
  return (
    <StyledSwitchEl onClick={toggle} isOn={isOn} disabled={disabled} className={className}>
      <Path />
      <Toggle />
    </StyledSwitchEl>
  );
};
