/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import logo from './img/gaming-logo.png';
import { JoinButton } from './JoinButton';

const Nav = () => {
  const params = useParams();

  return (
    <>
      <nav className="bg-zomzzydark/70 z-50">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-10">
          <div className="relative flex h-14 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img className="h-12 w-auto" src={logo} alt="Game" />
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex">
                  <Link
                    to={!params.viewFrom ? '/' : `/${params.viewFrom}`}
                    className="border-b-2 text-white mx-3 py-2 text-xs font-medium"
                    aria-current="page"
                  >
                    Game Pass
                  </Link>
                  <Link
                    to="#"
                    className="text-gray-300 hover:border-b-2 hover:text-white mx-3 py-2 text-xs font-medium"
                  >
                    Games
                  </Link>
                  <Link
                    to="#"
                    className="text-gray-300 hover:border-b-2 hover:text-white mx-3 py-2 text-xs font-medium"
                  >
                    Devices
                  </Link>
                  <Link
                    to="#"
                    className="text-gray-300 hover:border-b-2 hover:text-white mx-3 py-2 text-xs font-medium"
                  >
                    Play
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="bg-zomzzydark/70 sticky top-0 z-40">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-10">
          <div className="relative flex h-14 items-center justify-between">
            <div className="flex flex-1 items-stretch justify-start">
              <div className="text-white py-2 font-bold text-lg">Game Pass</div>
              <div className="hidden lg:flex items-center ml-4">
                <Link to="#" className="text-white underline mx-4 py-2 font-bold text-sm">PC Game Pass</Link>
                <Link to="#" className="text-white underline mx-4 py-2 font-bold text-sm">Cloud Gaming</Link>
                <Link to="#" className="text-white underline mx-4 py-2 font-bold text-sm">Devices</Link>
                <Link to="#" className="text-white underline mx-4 py-2 font-bold text-sm">FAQ</Link>
              </div>
            </div>
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
            >
              <div className="relative ml-3">
                <JoinButton
                  id="join-nav-button"
                  className="flex bg-transparent text-xbblue"
                  to={!params.viewFrom ? '/pricing' : `/${params.viewFrom}/pricing`}
                >
                  JOIN NOW
                </JoinButton>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
