/* eslint-disable camelcase */
import React, {
  useEffect, useRef,
} from 'react';
import { useParams } from 'react-router';
import bgImageBlank from './background/bg-image-blank.jpg';
import { useDynamicPackage } from './DynamicPackageContext';
import Nav from './Nav';
import { Product } from './Product';
import { Switch } from './Switch';

const Pricing = () => {
  const initDone = useRef(false);
  const params = useParams();

  const {
    loading,
    products,
    runDynamicPackageDecisions,
    billingPeriod,
    setBillingPeriod,
    canSwitchPlan,
  } = useDynamicPackage();

  useEffect(() => {
    if (!loading) {
      if (!initDone.current) {
        initDone.current = true;
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!initDone.current) {
      runDynamicPackageDecisions({
        viewFrom: params.viewFrom,
      });
    }
  }, [runDynamicPackageDecisions, params.viewFrom]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Nav />
      <section
        id="bg"
        className="relative flex flex-col bg-cover bg-center bg-no-repeat aspect-video -mt-28"
        style={{ backgroundImage: `url(${bgImageBlank})` }}
      >
        <div className="relative mx-auto px-4 pt-40 pb-20 sm:px-6 lg:flex lg:px-10">
          <h1 className="text-3xl xl:text-4xl font-bold text-center text-zinc-100 drop-shadow-lg">
            Choose the plan that&apos;s right for you
          </h1>
          <div className="mx-auto text-center px-10 flex justify-center gap-8">
            {canSwitchPlan && (
              <div className="flex items-center gap-2">
                Monthly
                <Switch
                  triggerAction={(on) => {
                    setBillingPeriod(on ? 'Annual' : 'Monthly');
                  }}
                  isOn={billingPeriod === 'Annual'}
                />
                Yearly
              </div>
            )}
          </div>
        </div>
        <div className="relative mx-auto max-w-7xl px-4 pb-40 sm:px-6 lg:flex lg:h-full lg:px-10">
          <div className="max-lg:space-y-8 lg:flex lg:flex-wrap gap-x-5 gap-y-6 justify-center">
            {products.map((p) => (
              <Product
                key={p.id}
                product={p}
                billingPeriod={billingPeriod}
                canSwitchPlan={canSwitchPlan}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
